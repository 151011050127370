import { ConfirmationDialog } from "@/components/dialog.tsx"
import type React from "react"
import { useState } from "react"

export type DialogParams = {
  isOpen?: boolean
  title?: string
  message?: string
  confirmLabel?: string
  onConfirm?: () => void
  onCancel?: () => void
}

export type DialogState = {
  render: () => React.ReactNode
  open: (params?: DialogParams) => void
  close: () => void
}

export function useDialog(initialParams?: DialogParams): DialogState {
  const [params, setParams] = useState(initialParams)

  const mergeParams = (updates: Partial<DialogParams>) => {
    setParams(current => ({ ...current, ...updates }))
  }

  const open = (params?: DialogParams) => mergeParams({ ...params, isOpen: true })
  const close = () => mergeParams({ isOpen: false })

  const onConfirm = () => {
    params?.onConfirm?.()
    close()
  }

  const onCancel = () => {
    params?.onCancel?.()
    close()
  }

  const render = () => <ConfirmationDialog {...params} onCancel={onCancel} onConfirm={onConfirm} />

  return {
    render,
    open,
    close
  }
}
