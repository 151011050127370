import Avatar from "@/components/avatar.tsx"
import { useLocalStorage } from "@/hooks/use-local-storage"
import type { SelectedUser } from "@/pages/expense-page/expense-page.tsx"
import type { UserProfile } from "@/types.ts"
import { firstNameLastInitialIfNeeded, fullName } from "@/utils/string-utils.ts"
import { ChevronUpDownIcon, UserGroupIcon } from "@heroicons/react/16/solid"
import { ChevronRight, MoreHoriz } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { type ComponentProps, type ReactNode, useState } from "react"

export function UserSelectionBar(props: {
  users: UserProfile[]
  selectedUser: SelectedUser | null
  onChange: (user: SelectedUser) => void
  onManageUsersClick: () => void
  showUserPalette?: boolean
}) {
  const { selectedUser, users, onChange } = props
  const isUserPaletteEnabled = props.showUserPalette ?? useLocalStorage().isUserPaletteEnabled

  return (
    <div className="fixed bottom-0 left-0 p-4 w-full">
      <div
        className="px-4 h-14 flex items-center justify-between border rounded-[55px] bg-white/80 backdrop-blur"
        style={{ boxShadow: "0 2px 8px 0 rgba(0,0,0,0.5)" }}
      >
        {isUserPaletteEnabled ? (
          <UserPalette {...props} />
        ) : (
          <>
            <div>Claiming items for:</div>
            <UserMenuButton
              users={users ?? []}
              onChange={onChange}
              onMoreClick={props.onManageUsersClick}
            >
              {selectedUser === "everyone" ? (
                <UserGroupIcon className="w-8 h-8" />
              ) : (
                <Avatar user={selectedUser} />
              )}
              {selectedUser === "everyone"
                ? "Everyone"
                : selectedUser
                  ? firstNameLastInitialIfNeeded(selectedUser, users)
                  : ""}
            </UserMenuButton>
          </>
        )}
      </div>
    </div>
  )
}

function UserPalette(props: ComponentProps<typeof UserSelectionBar>) {
  return (
    <div className="flex justify-between items-center flex-grow gap-1">
      {props.users?.map(user => (
        <div
          className="flex flex-col flex-grow items-center "
          key={user.id}
          onClick={() => props.onChange(user)}
        >
          <Avatar
            user={user}
            className={
              props.selectedUser !== "everyone" && user.id === props.selectedUser?.id ? "ring" : ""
            }
          />
          <div className="text-xs">{user.firstName}</div>
        </div>
      ))}
      <IconButton onClick={props.onManageUsersClick}>
        <MoreHoriz />
      </IconButton>
    </div>
  )
}

function UserMenuButton(props: {
  children: ReactNode
  users: UserProfile[]
  onChange: (user: SelectedUser) => void
  onMoreClick: () => void
}) {
  const [isOpen, setIsOpen] = useState(false)

  const handleItemClick = (user: SelectedUser) => {
    setIsOpen(false)
    props.onChange(user)
  }

  const handleMoreUsersClick = () => {
    props.onMoreClick()
    setIsOpen(false)
  }

  return (
    <>
      <button
        type="button"
        className="flex items-center gap-2 text-gray-500 py-2 pl-4 rounded"
        onClick={() => setIsOpen(isOpen => !isOpen)}
      >
        {props.children}
        <ChevronUpDownIcon className="w-6 h-6" />
      </button>

      {isOpen && (
        <ul className="fixed right-0 bottom-16 shadow-lg rounded border drop-shadow bg-white/95 backdrop-blur">
          {props.users?.map(item => (
            <MenuItem key={item.id} onClick={() => handleItemClick(item)}>
              <Avatar user={item} />
              {fullName(item)}
            </MenuItem>
          ))}
          {props.users.length > 1 && (
            <MenuItem onClick={() => handleItemClick("everyone")}>
              <UserGroupIcon className="w-8 h-8" />
              Everyone
            </MenuItem>
          )}
          <MenuItem onClick={handleMoreUsersClick} rightIcon={<ChevronRight />}>
            More
          </MenuItem>
        </ul>
      )}
    </>
  )
}

function MenuItem(props: { children: ReactNode; onClick: () => void; rightIcon?: ReactNode }) {
  return (
    <li
      className="min-h-12 border-b last:border-b-0 flex items-center justify-between px-6 pr-2 gap-2 cursor-pointer"
      onClick={props.onClick}
    >
      <div className="flex items-center gap-2 pr-4">{props.children}</div>
      {props.rightIcon}
    </li>
  )
}
