import { Button } from "@/components/button"
import EmptyState from "@/components/empty-state.tsx"
import { useCreateExpense, useExpenses } from "@/services/expense-service"
import { roundToPlaces } from "@/utils/number-utils.ts"
import { orderBy } from "lodash"
import { type ChangeEvent, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"

export default function HomePage() {
  const navigate = useNavigate()
  const { createExpense, progress, error } = useCreateExpense()
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const { expenses } = useExpenses()
  const sortedExpenses = orderBy(expenses ?? [], "createdAt", "desc")

  const handleUploadClick = async (evt: ChangeEvent<HTMLInputElement>) => {
    const file = evt.target.files?.[0]
    if (!file) return

    const megabytes = roundToPlaces(file.size / 1e6, 1)
    if (megabytes > 4) {
      alert(`That file is too large (${megabytes}MB). Must be smaller than 4MB.`)
      return
    }

    const expense = await createExpense(file)
    if (expense) {
      navigate(`/expense/${expense.id}`)
    }
  }

  return (
    <div className="min-h-full w-full flex items-center justify-center">
      {progress && <EmptyState progress title={progress.title} subtitle={progress.subtitle} />}
      {error && <EmptyState title="Error" subtitle={error.message} />}
      {!progress && !error && (
        <div className="flex flex-col gap-8">
          <div>
            {sortedExpenses?.map(expense => (
              <Link
                to={`/expense/${expense.id}`}
                key={expense.id}
                className="flex items-center gap-4 h-10"
              >
                {expense.name}
              </Link>
            ))}
          </div>
          <Button onClick={() => fileInputRef.current?.click()} color="blue">
            Upload receipt
          </Button>
          <input
            onChange={handleUploadClick}
            multiple={false}
            ref={fileInputRef}
            type="file"
            hidden
          />
        </div>
      )}
    </div>
  )
}
