import { runPromptWithImage } from "@/services/ai-service.ts"
import { db } from "@/services/firebase.ts"
import type {
  CreateExpenseDto,
  Expense,
  ExpenseItem,
  UpdateExpenseDto,
  UserProfile
} from "@/types.ts"
import { parseNumber } from "@/utils/number-utils.ts"
import { useCollection, useDoc } from "@tatsuokaniwa/swr-firestore"
import {
  Timestamp,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
  writeBatch
} from "firebase/firestore"
import { omit } from "lodash"
import { useState } from "react"

const expensesCollection = collection(db, "expenses")

export function useExpenses() {
  const expenses = useCollection<Expense>({
    path: "expenses",
    where: [["createdBy", "==", "kaiden"]]
  })
  return { expenses: expenses.data, error: expenses.error }
}

export function useExpenseData(id: string | null) {
  const expense = useDoc<Expense>(id ? { path: `expenses/${id}` } : null)
  const items = useCollection<ExpenseItem>(id ? { path: `expenses/${id}/items` } : null)
  const memberIds = expense.data?.memberIds ?? []
  const members = useCollection<UserProfile>(
    memberIds.length
      ? { path: "user_profiles", where: [["id", "in", memberIds]], orderBy: [["firstName", "asc"]] }
      : null
  )

  return {
    expense: expense.data,
    items: items.data,
    members: members.data
  }
}

export function useCreateExpense() {
  const [progress, setProgress] = useState<{ title: string; subtitle: string } | null>(null)
  const [error, setError] = useState<Error | null>(null)

  const createExpenseFn = async (file: File): Promise<Expense | null> => {
    setProgress({ title: "Analyzing", subtitle: "Analyzing receipt image..." })
    try {
      const responseText = await runPromptWithImage(
        `Analyze this image of a receipt and return a purely JSON response that includes these properties: 
      merchant, date (the full date and time), subtotal, tax, tip (the amount paid for the tip), total, and items (an array of the receipt items).
      Each item should include these properties: name, quantity, total. Return a JSON response.
    `,
        file
      )

      const data = parseJsonResponse(responseText)
      if (!data) return null

      const expenseData: CreateExpenseDto = {
        name: data.merchant ?? "New Tab",
        date: parseDate(data.date),
        subtotal: parseNumber(data.subtotal) ?? 0,
        tax: parseNumber(data.tax) ?? 0,
        tip: parseNumber(data.tip) ?? 0,
        total: parseNumber(data.total) ?? 0,
        memberIds: ["kaiden"],
        createdBy: "kaiden"
      }
      const itemsData = (data.items ?? []).map(item => ({
        name: item.name.replace(new RegExp(`^${item.quantity} `), ""),
        quantity: item.quantity ?? 1,
        total: parseNumber(item.total) ?? 0
      }))

      setProgress({ title: "Creating", subtitle: "Creating your new tab..." })
      return createExpense(expenseData, itemsData)
    } catch (err) {
      setError(err as Error)
      console.error(err)
      return null
    }
  }

  return { createExpense: createExpenseFn, progress, error }
}

function parseDate(date: string | undefined): Timestamp | undefined {
  if (!date) return undefined
  try {
    return Timestamp.fromDate(new Date(date))
  } catch (err) {
    console.error("Error parsing date", err, date)
    return undefined
  }
}

type ParsedExpense = Partial<{
  merchant: string
  date: string
  subtotal: number
  tax: number
  tip: number
  total: number
  items: ParsedItem[]
}>

type ParsedItem = { name: string; quantity?: number; total?: number }

function parseJsonResponse(response: string): ParsedExpense | null {
  const json = response.replace(/^```json/, "").replace(/```$/, "")
  try {
    console.log("parsing", json)
    return JSON.parse(json)
  } catch (err) {
    console.log("Unable to parse response as JSON", err, json)
    return null
  }
}

export async function createExpense(
  expense: CreateExpenseDto,
  items: Omit<ExpenseItem, "id">[]
): Promise<Expense> {
  const batch = writeBatch(db)
  const expenseRef = doc(expensesCollection)
  const { id } = expenseRef
  const data = {
    ...omit(expense, "id"),
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp()
  }
  batch.set(expenseRef, data)

  const itemsCollection = collection(db, `expenses/${id}/items`)
  for (const item of items) {
    const itemRef = doc(itemsCollection)
    batch.set(itemRef, item)
  }

  await batch.commit()

  const createdExpense = (await getDoc(expenseRef)).data() as Expense
  return { ...createdExpense, id }
}

export async function updateExpense(expenseId: string, updates: UpdateExpenseDto): Promise<void> {
  const expenseRef = doc(expensesCollection, expenseId)
  await updateDoc(expenseRef, updates)
}

export async function updateExpenseItem(
  expenseId: string,
  itemId: string,
  updates: Partial<ExpenseItem>
): Promise<void> {
  const expenseItemsCollection = collection(db, "expenses", expenseId, "items")
  const docRef = doc(expenseItemsCollection, itemId)

  await updateDoc(docRef, updates)
}

export async function addExpenseMember(expenseId: string, userId: string) {
  const expenseRef = doc(expensesCollection, expenseId)
  await updateDoc(expenseRef, { memberIds: arrayUnion(userId) })
}

export async function removeExpenseMember(expenseId: string, userId: string) {
  const expenseRef = doc(expensesCollection, expenseId)
  await updateDoc(expenseRef, { memberIds: arrayRemove(userId) })
  await removeUserFromAllExpenseItems(userId, expenseId)
}

export async function removeUserFromAllExpenseItems(userId: string, expenseId: string) {
  const expenseItemsCollection = collection(db, "expenses", expenseId, "items")
  const q = query(expenseItemsCollection, where("userIds", "array-contains", userId))
  const snap = await getDocs(q)
  const refs = snap.docs.map(doc => doc.ref)
  const batch = writeBatch(db)

  for (const ref of refs) {
    batch.update(ref, { userIds: arrayRemove(userId) })
  }

  await batch.commit()
}
