import { IosShare } from "@mui/icons-material"
import { IconButton } from "@mui/material"

export default function ShareButton(props: { title?: string; url: string }) {
  const shareData = { url: props.url, title: props.title }
  const canShare = navigator.canShare?.(shareData)
  if (!canShare) return null

  return (
    <IconButton key="share" onClick={() => navigator.share(shareData)} size="small">
      <IosShare />
    </IconButton>
  )
}
