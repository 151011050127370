import { db } from "@/services/firebase.ts"
import type { CreateUserProfileDto, UserProfile } from "@/types.ts"
import { type Timestamp, addDoc, collection, serverTimestamp } from "firebase/firestore"
import { omit } from "lodash"

const userProfilesCollection = collection(db, "user_profiles")

export async function createUserProfile(profile: CreateUserProfileDto): Promise<UserProfile> {
  const data = {
    ...omit(profile, "id"),
    createdAt: serverTimestamp() as Timestamp,
    updatedAt: serverTimestamp() as Timestamp
  }
  const ref = await addDoc(userProfilesCollection, data)
  return { ...(data as UserProfile), id: ref.id }
}
