import { motion } from "framer-motion"

const ShowLoadingScreenAfterDays = 1

export default function LoadingScreen() {
  const showLoadingScreenAfterDate =
    new Date().getTime() - 1000 * 60 * 60 * 24 * ShowLoadingScreenAfterDays
  const loadingScreenDate = localStorage.getItem("loadingScreenDate")
  const shouldShowLoadingScreen =
    !loadingScreenDate || new Date(loadingScreenDate).getTime() < showLoadingScreenAfterDate

  if (!shouldShowLoadingScreen) return null

  const handleAnimationComplete = () => {
    localStorage.setItem("loadingScreenDate", new Date().toISOString())
  }

  return (
    <motion.div
      className="fixed z-[1000] w-full h-full flex items-center justify-center"
      style={{
        backgroundImage:
          "radial-gradient(circle at center, rgba(255,255,255,0.5), transparent), linear-gradient(to right, #48A2A0, #697DB4)"
      }}
      animate={{ opacity: 0, transitionEnd: { display: "none" } }}
      transition={{ duration: 0.25, delay: 1.5 }}
      onAnimationComplete={handleAnimationComplete}
    >
      <motion.img
        src="/logo-shadow.png"
        alt="SnapTab Logo"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 700,
          damping: 20,
          duration: 0.8,
          delay: 1
        }}
      />
    </motion.div>
  )
}
