import type { UserProfile } from "@/types.ts"
import classNames from "classnames"
import { type ReactNode, createContext, useContext, useMemo } from "react"

export default function Avatar(props: {
  user: UserProfile | null
  className?: string
}) {
  if (!props.user) return null

  const usersInContext = useContext(AvatarContext)?.users ?? []

  const initials = useMemo(() => {
    if (!props.user) return ""
    const first = props.user.firstName?.[0] ?? ""
    const last = getLastInitial(props.user, usersInContext)
    return first + last
  }, [props.user, usersInContext])

  return (
    <div
      className={classNames(
        props.className,
        "rounded-full border w-8 h-8 flex items-center justify-center shrink-0 relative overflow-hidden"
      )}
    >
      {initials}
      <div
        className="bg-center bg-contain absolute top-0 right-0 w-full h-full"
        style={props.user.imageUrl ? { backgroundImage: `url(${props.user.imageUrl})` } : undefined}
      />
    </div>
  )
}

export function getLastInitial(user: UserProfile, usersInContext: UserProfile[]) {
  const { firstName, lastName, id } = user
  const firstInitial = firstName?.[0] ?? ""
  const lastInitial = lastName?.[0]
  const usersWithSameFirstInitial = usersInContext.filter(u => u.firstName?.[0] === firstInitial)

  if (usersWithSameFirstInitial.length === 1) return ""
  if (lastInitial?.length) return lastInitial
  const number = usersWithSameFirstInitial.findIndex(u => u.id === id) + 1
  return number.toString()
}

const AvatarContext = createContext<AvatarContextValue>({ users: [] })

export interface AvatarContextValue {
  users: UserProfile[]
}

export function AvatarContextProvider(props: { users: UserProfile[]; children: ReactNode }) {
  return (
    <AvatarContext.Provider value={{ users: props.users }}>{props.children}</AvatarContext.Provider>
  )
}
