import { DetailLine } from "@/pages/expense-page/expense-page"
import type { Expense, ExpenseItem, UserProfile } from "@/types"
import { firstNameLastInitialIfNeeded, toCurrency } from "@/utils/string-utils"
import { useMemo } from "react"

export default function Balances(props: {
  expense: Expense
  items: ExpenseItem[]
  users: UserProfile[]
  tipPercent: number
}) {
  const { expense, items, users, tipPercent } = props
  const hasTax = !!expense.tax
  const hasTip = !!expense.tip

  const balances = useMemo<UserExpenseBreakdown[]>(
    () =>
      users.map(user => {
        let count = 0
        const subtotal = items.reduce((total, item) => {
          const userIds = item.userIds ?? []
          if (userIds.length === 0) return total

          const userItemShares = userIds.filter(id => id === user.id).length
          if (userItemShares > 0) count++
          return total + userItemShares * (item.total / userIds.length)
        }, 0)
        const tax = (subtotal / (expense.subtotal ?? 1)) * (expense.tax ?? 0)
        const tip = (tipPercent * subtotal) / 100

        return {
          user,
          subtotal,
          tax,
          tip,
          total: subtotal + tip + tax,
          count
        }
      }),
    [expense, items, users, tipPercent]
  )

  const unclaimed = useMemo<ExpenseBreakdown>(() => {
    let count = 0

    const subtotal = items.reduce((total, item) => {
      const isUnclaimed = !item.userIds?.length
      if (isUnclaimed) count++
      return isUnclaimed ? item.total + total : total
    }, 0)

    const tax = (subtotal / (expense.subtotal ?? 1)) * (expense.tax ?? 0)
    const tip = (tipPercent * subtotal) / 100

    return { count, total: subtotal + tax + tip, subtotal, tax, tip }
  }, [expense, items, tipPercent])

  const breakdownParts: (keyof ExpenseBreakdown)[] = ["subtotal"]
  if (hasTax) breakdownParts.push("tax")
  if (hasTip) breakdownParts.push("tip")
  const isBreakdownVisible = breakdownParts.length > 1

  return (
    <div>
      {isBreakdownVisible && (
        <div className="text-end text-gray-400 text-xs">({breakdownParts.join(" + ")})</div>
      )}
      {balances.map(b => {
        const name = firstNameLastInitialIfNeeded(b.user, users)
        return (
          <DetailLine
            name={name + (b.total < 0 ? " is owed" : " owes")}
            details={
              isBreakdownVisible &&
              b.total > 0 && (
                <span>({breakdownParts.map(partName => toCurrency(b[partName])).join(" + ")})</span>
              )
            }
            key={b.user.id}
          >
            {toCurrency(Math.abs(b.total))}
          </DetailLine>
        )
      })}

      {unclaimed.total > 0 && (
        <DetailLine
          name={`${unclaimed.count} unclaimed`}
          className="text-red-600"
          details={
            isBreakdownVisible &&
            `(${breakdownParts.map(partName => toCurrency(unclaimed[partName])).join(" + ")})`
          }
        >
          {toCurrency(unclaimed.total)}
        </DetailLine>
      )}
    </div>
  )
}

interface ExpenseBreakdown {
  subtotal: number
  tax: number
  tip: number
  total: number
  count: number
}

interface UserExpenseBreakdown extends ExpenseBreakdown {
  user: UserProfile
}
