import useIsScrolled from "@/hooks/use-is-scrolled.ts"
import classNames from "classnames"
import type { ReactNode } from "react"
import { useNavigate } from "react-router-dom"

export default function PageHeader(props: {
  name: string
  backLabel?: string
  trailingButtons?: ReactNode[]
  subHeader?: ReactNode
}) {
  const navigate = useNavigate()
  const isScrolled = useIsScrolled()

  return (
    <div
      className={classNames(
        "backdrop-blur bg-white/75 sticky top-0 w-screen z-10 border-0 transition-all",
        isScrolled && "shadow-lg"
      )}
    >
      <div className="flex justify-between items-center px-2 py-2">
        <div>
          {props.backLabel && (
            <button type="button" onClick={() => navigate("/")}>
              {props.backLabel}
            </button>
          )}
        </div>
        <div className="flex gap-4">{props.trailingButtons}</div>
      </div>

      <div className="px-4 font-bold text-3xl font-abel">{props.name}</div>

      {props.subHeader && <div className="text-sm px-4 pt-1 pb-4">{props.subHeader}</div>}
    </div>
  )
}
