import { AnimatePresence, motion } from "framer-motion"
import type { ReactNode } from "react"

export type SheetProps = {
  isOpen: boolean
  onClose: () => void
  children?: ReactNode
  className?: string
}

export default function Sheet(props: SheetProps) {
  return (
    <AnimatePresence>
      <motion.div
        className="hidden flex-col fixed w-screen h-dvh backdrop-blur bg-white/75 top-[100vh] overflow-auto overscroll-contain z-10"
        animate={props.isOpen ? { top: 0, display: "flex" } : {}}
        transition={{ ease: [0, 0, 0.205, 1.005] }}
        exit={{ top: "100vh", transitionEnd: { display: "none" } }}
      >
        {props.children}
      </motion.div>
    </AnimatePresence>
  )
}
