import { createBrowserRouter, RouterProvider } from "react-router-dom"
import HomePage from "@/pages/home-page.tsx"
import ExpensePage from "@/pages/expense-page/expense-page.tsx"
import { Layout } from "@/layout.tsx"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <HomePage key="home" /> },
      { path: "/expense/:id", element: <ExpensePage key="expense" /> }
    ]
  }
])

export default function Routes() {
  return <RouterProvider router={router} />
}
