import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyAgpl6NY-YgAgWBwzNgrocncfQmvk6d6xw",
  authDomain: "snaptab-a283a.firebaseapp.com",
  projectId: "snaptab-a283a",
  storageBucket: "snaptab-a283a.appspot.com",
  messagingSenderId: "872221403179",
  appId: "1:872221403179:web:e7ee40953b5337171c626e",
  measurementId: "G-HRSXZ43FZZ"
}

export const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const auth = getAuth(app)
